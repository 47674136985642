@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }
  h2 {
    @apply text-2xl;
    @apply font-bold;
  }
  h3 {
    @apply text-lg;
    @apply font-semibold;
  }
  a {
    @apply text-blue-600 underline;
  }
}
body {
  height: 100vh;
  width: 100vw;
  background-color: white;
  margin: 0;
  font-family:
    'Open Sans',
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: underline;
  color: #3b82f6;
}

.statistical-report-filter .rs-picker .rs-picker-toggle {
  box-shadow: none !important;
  border: none !important;
  background-color: #e7f2fd !important;
  border-radius: 60px !important;
  justify-content: start !important;
}

.statistical-report-filter .rs-picker-select .rs-picker-toggle {
  padding: 8px 20px !important;
}

.statistical-report-filter .rs-picker-tag .rs-picker-toggle {
  padding: 5px 20px !important;
}

.statistical-report-filter .rs-picker-tag {
  box-shadow: none !important;
  border: none !important;
  background-color: #e7f2fd !important;
  border-radius: 60px !important;
  padding: 3px 10px !important;
  justify-content: start !important;
}

.statistical-report-filter .rs-picker .rs-picker-caret-icon {
  fill: #126fd6 !important;
}

.statistical-report-filter .rs-picker-toggle-value {
  font-size: 16px !important;
  line-height: 24px !important;
  color: #2f363d !important;
}

.statistical-report-filter .rs-picker-select .rs-picker-caret-icon {
  margin-top: 4px;
}

.statistical-report-filter .rs-picker-toggle-label:after {
  content: '' !important;
}

.statistical-report-filter .rs-picker-select .rs-stack-item {
  height: 24px !important;
}

.statistical-report-filter .rs-picker-toggle-placeholder {
  font-size: 16px !important;
  line-height: 24px !important;
}

.popup {
  background-color: #f6f7f8 !important;
}

.popup .rs-picker-select-menu-item {
  color: #2f363d !important;
  font-weight: 400 !important;
}

.popup .rs-picker-select-menu-item-active {
  background-color: #eef0f2 !important;
}

.popup .rs-picker-select-menu-item:hover {
  background-color: #eef0f2 !important;
}

.popup .rs-checkbox-checker > label {
  color: #2f363d !important;
  font-weight: 400 !important;
}

.popup .rs-checkbox-checked {
  background-color: #eef0f2 !important;
}
.popup .rs-check-item-focus {
  background-color: inherit !important;
}

.popup .rs-check-item:not(.rs-checkbox-disabled):hover {
  background-color: #eef0f2 !important;
}

.custom-tag {
  background-color: transparent !important;
  color: #2f363d !important;
  font-size: 16px !important;
  margin-left: 0px !important;
}

.custom-tag:not(:last-child):after {
  content: ',';
}

.custom-tag:not(:first-child) {
  padding-left: 0px !important;
}

.popup .rs-checkbox-inner:before {
  border-width: px !important;
  border-color: #454f5a !important;
}
.popup .rs-checkbox-checked .rs-checkbox-inner:before {
  background-color: #126fd6 !important;
  border-color: #126fd6 !important;
}

.conversation ol {
  list-style: decimal;
  padding-left: 2rem;
  padding-top: 20px;
  padding-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 50px 0;
  text-align: left;
}
th,
td {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 24px 12px;
}

@keyframes dropBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes minimize {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes maximize {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.maximize-animation {
  animation: maximize 0.5s ease-in-out forwards;
}

.minimize-animation {
  animation: minimize 0.5s ease-in-out forwards;
}
